import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Global, css } from "@emotion/react";
import { GlobalContainer } from "../../styles/typography";
import Footer from "./footer";

const Layout = ({ children, footer = true }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <>
        <Global
          styles={css`
            body {
              margin: 0;
              padding: 0;
              font-family: "Nunito Sans", sans-serif;
            }

            li {
              list-style: none;
            }

            :root {
              --color1: #616161;
              --color2: #000000;
              --color3: #ffffff;
            }

            input,
            textarea {
              font-family: "Nunito Sans", sans-serif;
            }
          `}
        />
        <GlobalContainer>
          <main>{children}</main>
          {footer && <Footer />}
        </GlobalContainer>
      </>
    )}
  />
);

export default Layout;
